<script>
    import { onMount } from "svelte";

    export let scheme;
    export let title;
    export let onNextPress;
    export let index;
    export let editable;
    export let loading;
    export let error;
    export let isTextarea = false;

    let messageInput;

    const handleKeyPress = (event) => {
        if (event?.key === "Enter" && !isTextarea) {
            onNextPress?.(messageInput?.value, index);
        }
    };

    onMount(() => {
        if (messageInput) {
            // First clear any existing content
            messageInput.value = messageInput.value || '';
            
            // Place cursor at the very beginning (position 0,0)
            messageInput.focus();
            
            // These next lines ensure cursor is at the start position
            if (isTextarea) {
                // For textareas, explicitly set selection to beginning
                setTimeout(() => {
                    messageInput.selectionStart = 0;
                    messageInput.selectionEnd = 0;
                }, 0);
            }
        }
    });
</script>

<div
    class="flex items-center mb-[20px] max-w-full user-message justify-end"
>
    <div
        class="self-end flex frame w-80 {isTextarea ? 'h-48' : 'h-32'} bg-white rounded-[10px] rounded-tl-[3px]
        border-t-2 shadow-[0_2px_4px_2px_rgba(0,0,0,0.06)]"
        style="border-color: {scheme.header};"
    >
        <div
            class="div inline-flex flex-col items-start gap-1 relative top-6 left-4"
        >
            <div
                class="text-gray-700 font-inter normal leading-6 break-words text-[14px]"
            >
                {title}
            </div>
            <div
                class="flex input relative w-72 {isTextarea ? 'h-32' : 'h-10'} rounded-[6px] border border-[#e2e8f0] overflow-hidden"
            >
                {#if isTextarea}
                    <textarea
                        disabled={!editable ? "disabled" : false}
                        bind:this={messageInput}
                        class="w-full h-full px-4 py-2 outline-none disabled:bg-white text-gray-700 font-inter normal leading-6 break-words text-[14px] resize-none"
                    ></textarea>
                {:else}
                    <input
                        disabled={!editable ? "disabled" : false}
                        bind:this={messageInput}
                        class="w-3/4 pl-4 outline-none disabled:bg-white text-gray-700 font-inter normal leading-6 break-words text-[14px]"
                        on:keydown={handleKeyPress}
                    />
                {/if}

                <button
                    on:click={() => onNextPress?.(messageInput?.value, index)}
                    class="absolute right-0 {isTextarea ? 'bottom-0' : ''} cursor-pointer"
                >
                    <div
                        class="w-[53px] h-10 px-4 pt-[9px] pb-2.5 rounded-tr rounded-br justify-center items-center inline-flex"
                        style="background-color: {(editable || loading) ? scheme.header : "transparent"};"
                    >
                        <div
                            class="grow shrink basis-0 self-stretch pl-0.5 pr-px pt-0.5 pb-px justify-center items-center inline-flex"
                        >
                            <div
                                class="w-[21px] h-[21px] relative flex-col justify-start items-start flex"
                            >
                                {#if editable}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M6.1875 3.375L11.8125 9L6.1875 14.625" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                {:else if loading}
                                    <svg
                                        class="spin"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path d="M21 12a9 9 0 11-6.219-8.56" />
                                    </svg>
                                {:else}
                                    <svg
                                        class="ml-[5px]"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g id="HiCheckCircle 1">
                                            <path
                                                id="Vector"
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M1.96875 10.5C1.96875 5.78812 5.78812 1.96875 10.5 1.96875C15.2119 1.96875 19.0312 5.78812 19.0312 10.5C19.0312 15.2119 15.2119 19.0312 10.5 19.0312C5.78812 19.0312 1.96875 15.2119 1.96875 10.5ZM13.6587 8.91275C13.7112 8.84279 13.7492 8.76305 13.7705 8.67821C13.7917 8.59336 13.7958 8.50512 13.7824 8.41868C13.7691 8.33225 13.7386 8.24935 13.6927 8.17486C13.6469 8.10037 13.5866 8.0358 13.5155 7.98494C13.4443 7.93408 13.3637 7.89796 13.2784 7.8787C13.1931 7.85944 13.1048 7.85743 13.0187 7.87278C12.9326 7.88813 12.8504 7.92055 12.777 7.96811C12.7036 8.01567 12.6404 8.07743 12.5913 8.14975L9.75975 12.1135L8.33875 10.6925C8.21435 10.5766 8.04981 10.5135 7.87979 10.5165C7.70978 10.5195 7.54757 10.5883 7.42733 10.7086C7.3071 10.8288 7.23822 10.991 7.23522 11.161C7.23222 11.3311 7.29533 11.4956 7.41125 11.62L9.38 13.5887C9.44737 13.6561 9.52857 13.7079 9.61799 13.7407C9.7074 13.7735 9.80288 13.7864 9.89779 13.7786C9.9927 13.7707 10.0848 13.7424 10.1676 13.6954C10.2505 13.6484 10.3221 13.5839 10.3775 13.5065L13.6587 8.91275Z"
                                                fill="#38A169"
                                            />
                                        </g>
                                    </svg>
                                {/if}
                            </div>
                        </div>
                    </div>
                </button>
            </div>
            {#if error}
                <span
                    class="text-red-500 absolute {isTextarea ? 'bottom-[0px]' : 'bottom-[30px]'} font-inter normal leading-6 break-words text-[12px]">{error}</span
                >
            {/if}
        </div>
    </div>
</div>

<style>
    /* You can include Tailwind CSS classes directly in your Svelte component's style tag */
    .input:focus {
        outline: none !important;
        border-color: rgb(229 231 235);
    }

    .spin {
        animation: spinAnimation 1s linear infinite; /* Define the spin animation */
    }

    @keyframes spinAnimation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>
